<template>
  <v-container>
    <v-row justify="center">
      <v-col cols ="12" sm="12" md="9" lg="7" xl="6">
        <pdf src="http://localhost:8080/Files/cartavacas.pdf" :page="1" style="width: 100%; border-style:solid; border-width: 1px; border-color: silver"></pdf>
        <!-- <pdf src="https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/freeculture.pdf" :page="1" style="display: inline-block; width: 75%"></pdf> -->
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols ="12" sm="9" md="6" lg="5" xl="4">
        <VueSignaturePad id="signature" width="100%" ref="signaturePad" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import pdf from 'vue-pdf'
  import { mapState } from "vuex";
  import Vue from 'vue';
  import VueSignaturePad from 'vue-signature-pad';

  Vue.use(VueSignaturePad);
//  import router from '../router'
  export default {
    components: {
      pdf
    },
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
      return {
        rutaFile: ''
      }
    },
    methods: {
    },
    mounted() {
      this.rutaFile = "./InfFiles/FA_202766298067.pdf";
//      alert(this.rutaFile);
    }
  }
</script>
<style>
  #signature {
    border-style: solid;
    border-width: 2px;
    border-color: grey;

  /* #signature {
    border: double 3px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #4bc5e8, #9f6274);
    background-origin: border-box;
    background-clip: content-box, border-box; */
  }  
</style>